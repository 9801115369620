////////////////////////////////////////////////////////////////////////////////////////////////////// Start Section Imports
import React from "react";
import { Search, Call, MailOutline } from "@mui/icons-material";
////////////////////////////////////////////////////////////////////////////////////////////////////// End Section Imports

////////////////////////////////////////////////////////////////////////////////////////////////////// Start Section Class
export default class FAQ extends React.Component {
  render() {
    return (
      <section className="p-3">
        <section className="row">
          <section className="row d-flex align-items-center justify-content-center">
            <section className="col-xl-12 p-2">
              <section className="bg-t-o b-r-10">
                <section className="col-12 p-3 d-flex flex-column align-items-center justify-content-center">
                  <h2 className="pb-3">سلام چجوری میتونیم کمک کنیم</h2>

                  <span>
                    یا یک دسته را انتخاب کنید تا به سرعت کمک مورد نیاز خود را
                    پیدا کنید
                  </span>

                  <section className="col-xl-4 pt-4">
                    <form className="form-search" method="GET">
                      <input
                        placeholder="سوال بپرس ...."
                        className="input-form-search"
                        name="Search"
                        required
                      />

                      <button type="submit" className="button-form-search">
                        <Search titleAccess="جستجوی کلمه مورد نظر" />
                      </button>
                    </form>
                  </section>
                </section>
              </section>
            </section>

            <section className="row d-flex align-items-center justify-content-center">
              <section className="d-flex align-items-center justify-content-center p-2">
                <section className="bg-t-o b-r-10 col-12 d-flex align-items-center justify-content-around p-3 flex-column">
                  <section className="row">
                    <section className="col-sm-12 col-md-12">
                      <section className="card custom-card bg-t-o">
                        <section className="card-body bg-t-o">
                          <section>
                            <h2 className="mb-5">سوالات اولیه</h2>
                            <p className="card-sub-title">
                              لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                              صنعت چاپ و با استفاده از طراحان گرافیک است
                            </p>
                          </section>
                          <h5>1. چگونه همه پلاگین ها را وارد کنیم؟</h5>
                          <p className="mb-0">
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                            صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها
                            و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که
                            لازم است و برای شرایط فآرش تکنولوژی مورد نیاز و
                            کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می
                            باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                            آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با
                            نرم افزارها شناخت بیشتری را برای طراحان رایانه ای
                            آرش الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی
                            ایجاد کرد. در این صورت می توان امید داشت که تمام و
                            دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به
                            پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای
                            اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی
                            اساسا مورد استفاده قرار گیرد.
                          </p>
                        </section>
                        <section className="card-body">
                          <h5>2. چگونه می توانم تماس بگیرم؟</h5>
                          <p className="mb-0">
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                            صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها
                            و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که
                            لازم است و برای شرایط فآرش تکنولوژی مورد نیاز و
                            کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می
                            باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                            آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با
                            نرم افزارها شناخت بیشتری را برای طراحان رایانه ای
                            آرش الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی
                            ایجاد کرد. در این صورت می توان امید داشت که تمام و
                            دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به
                            پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای
                            اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی
                            اساسا مورد استفاده قرار گیرد.
                          </p>
                        </section>
                        <section className="card-body">
                          <h5>
                            3. آیا می توانم از این افزونه ها در قالب دیگری
                            استفاده کنم؟
                          </h5>
                          <p className="mb-0">
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                            صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها
                            و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که
                            لازم است و برای شرایط فآرش تکنولوژی مورد نیاز و
                            کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می
                            باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                            آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با
                            نرم افزارها شناخت بیشتری را برای طراحان رایانه ای
                            آرش الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی
                            ایجاد کرد. در این صورت می توان امید داشت که تمام و
                            دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به
                            پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای
                            اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی
                            اساسا مورد استفاده قرار گیرد.
                          </p>
                        </section>
                        <section className="card-body">
                          <h5>
                            4. چگونه می توانم صفحه دیگری را در قالب اضافه کنم؟
                          </h5>
                          <p className="mb-0">
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                            صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها
                            و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که
                            لازم است و برای شرایط فآرش تکنولوژی مورد نیاز و
                            کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می
                            باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                            آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با
                            نرم افزارها شناخت بیشتری را برای طراحان رایانه ای
                            آرش الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی
                            ایجاد کرد. در این صورت می توان امید داشت که تمام و
                            دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به
                            پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای
                            اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی
                            اساسا مورد استفاده قرار گیرد.
                          </p>
                        </section>
                        <section className="card-body">
                          <h5>5. قابل تنظیم آسان است؟</h5>
                          <p className="mb-0">
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                            صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها
                            و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که
                            لازم است و برای شرایط فآرش تکنولوژی مورد نیاز و
                            کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می
                            باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                            آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با
                            نرم افزارها شناخت بیشتری را برای طراحان رایانه ای
                            آرش الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی
                            ایجاد کرد. در این صورت می توان امید داشت که تمام و
                            دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به
                            پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای
                            اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی
                            اساسا مورد استفاده قرار گیرد.
                          </p>
                        </section>
                        <section className="card-body">
                          <h5>6. چگونه می توانم این قالب را دانلود کنم؟</h5>
                          <p className="mb-0">
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                            صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها
                            و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که
                            لازم است و برای شرایط فآرش تکنولوژی مورد نیاز و
                            کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می
                            باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                            آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با
                            نرم افزارها شناخت بیشتری را برای طراحان رایانه ای
                            آرش الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی
                            ایجاد کرد. در این صورت می توان امید داشت که تمام و
                            دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به
                            پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای
                            اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی
                            اساسا مورد استفاده قرار گیرد.
                          </p>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>

            <section className="row d-flex align-items-center justify-content-center">
              <section className="d-flex align-items-center justify-content-center p-2">
                <section className="bg-t-o b-r-10 col-12 d-flex align-items-center justify-content-around p-3 flex-column">
                  <span>شما هنوز یک سوال دارید؟</span>

                  <span className="pt-3">
                    اگر نمی توانید سؤالی را در سؤالات متداول ما پیدا کنید، همیشه
                    می توانید با ما تماس بگیرید. ما به زودی به شما پاسخ خواهیم
                    داد!
                  </span>
                </section>
              </section>
            </section>

            <section className="row d-flex align-items-center justify-content-center">
              <section className="d-flex align-items-center justify-content-center p-2">
                <section className="bg-t-o b-r-10 col-12 d-flex align-items-center justify-content-around p-3">
                  <section className="col-5 b-r-5 bg-main p-3 d-flex flex-column align-items-center justify-content-center">
                    <Call />

                    <a
                      target="_blank"
                      href="#"
                      rel="noreferrer"
                      className="text-white pt-3"
                    >
                      واتساپ من
                    </a>

                    <span className="pt-3">
                      بهترین راه برای دریافت سریعتر پاسخ
                    </span>
                  </section>

                  <section className="col-5 b-r-5 bg-main p-3 d-flex flex-column align-items-center justify-content-center">
                    <MailOutline />

                    <a
                      target="_blank"
                      href="#"
                      rel="noreferrer"
                      className="text-white pt-3"
                    >
                      خرید میستر پنل
                    </a>

                    <span className="pt-3">برای خرید میستر پنل پیام دهید</span>
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    );
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////// End Section Class
