////////////////////////////////////////////////////////////////////////////////////////////////////// Start Section Imports
import React from "react";
import {
  Abc,
  AcUnit,
  AccessAlarm,
  AccessAlarms,
  AccessTime,
  AccessTimeFilled,
  Accessibility,
  AccessibilityNew,
  Accessible,
  AccessibleForward,
  AccountBalance,
  AccountBalanceWallet,
  AccountBox,
  AccountCircle,
  AccountTree,
  AdUnits,
  Adb,
  Add,
  AddAPhoto,
  AddAlarm,
  AddAlert,
  AddBox,
  AddBusiness,
  AddCard,
  AddCircle,
  AddCircleOutline,
  AddComment,
  AddIcCall,
  AddLink,
  AddLocation,
  AddLocationAlt,
  AddModerator,
  AddPhotoAlternate,
  AddReaction,
  AddRoad,
  AddShoppingCart,
  AddTask,
  AddToDrive,
  AddToHomeScreen,
  AddToPhotos,
  AddToQueue,
  Addchart,
  AdfScanner,
  Adjust,
  AdminPanelSettings,
  Agriculture,
  Air,
  AirlineSeatFlat,
  AirlineSeatFlatAngled,
  AirlineSeatIndividualSuite,
  AirlineSeatLegroomExtra,
  AirlineSeatLegroomNormal,
  AirlineSeatLegroomReduced,
  AirlineSeatReclineExtra,
  AirlineSeatReclineNormal,
  AirlineStops,
  Airlines,
  AirplaneTicket,
  AirplanemodeActive,
  AirplanemodeInactive,
  Airplay,
  AirportShuttle,
  Alarm,
  AlarmAdd,
  AlarmOff,
  AlarmOn,
  Album,
  AlignHorizontalCenter,
  AlignHorizontalLeft,
  AlignHorizontalRight,
  AlignVerticalBottom,
  AlignVerticalCenter,
  AlignVerticalTop,
  AllInbox,
  AllInclusive,
  AllOut,
  AltRoute,
  AlternateEmail,
  Analytics,
  Anchor,
  Android,
  Animation,
  Announcement,
  Aod,
  Apartment,
  Api,
  AppBlocking,
  AppRegistration,
  AppSettingsAlt,
  AppShortcut,
  Apple,
  Approval,
  Apps,
  AppsOutage,
  Architecture,
  Archive,
  ArrowBack,
  ArrowBackIos,
  ArrowBackIosNew,
  ArrowCircleDown,
  ArrowCircleLeft,
  ArrowCircleRight,
  ArrowCircleUp,
  ArrowDownward,
  ArrowDropDown,
  ArrowDropDownCircle,
  ArrowDropUp,
  ArrowForward,
  ArrowForwardIos,
  ArrowLeft,
  ArrowRight,
  ArrowRightAlt,
  ArrowUpward,
  ArtTrack,
  Article,
  AspectRatio,
  Assessment,
  Assignment,
  AssignmentInd,
  AssignmentLate,
  AssignmentReturn,
  AssignmentReturned,
  AssignmentTurnedIn,
  Assistant,
  AssistantDirection,
  AssistantPhoto,
  AssuredWorkload,
  Atm,
  AttachEmail,
  AttachFile,
  AttachMoney,
  Attachment,
  Attractions,
  Attribution,
  AudioFile,
  Audiotrack,
  AutoAwesome,
  AutoAwesomeMosaic,
  AutoAwesomeMotion,
  AutoDelete,
  AutoFixHigh,
  AutoFixNormal,
  AutoFixOff,
  AutoGraph,
  AutoMode,
  AutoStories,
  AutofpsSelect,
  Autorenew,
  AvTimer,
  BabyChangingStation,
  Backpack,
  Backspace,
  Backup,
  BackupTable,
  Badge,
  BakeryDining,
  Balance,
  Balcony,
  Ballot,
  BarChart,
  BatchPrediction,
  Bathroom,
  Bathtub,
  Battery0Bar,
  Battery1Bar,
  Battery20,
  Battery2Bar,
  Battery30,
  Battery3Bar,
  Battery4Bar,
  Battery50,
  Battery5Bar,
  Battery60,
  Battery6Bar,
  Battery80,
  Battery90,
  BatteryAlert,
  BatteryCharging20,
  BatteryCharging30,
  BatteryCharging50,
  BatteryCharging60,
  BatteryCharging80,
  BatteryCharging90,
  BatteryChargingFull,
  BatteryFull,
  BatterySaver,
  BatteryStd,
  BatteryUnknown,
  BeachAccess,
  Bed,
  BedroomBaby,
  BedroomChild,
  BedroomParent,
  Bedtime,
  BedtimeOff,
  Beenhere,
  Bento,
  BikeScooter,
  Biotech,
  Blender,
  BlindsClosed,
  Block,
  Bloodtype,
  Bluetooth,
  BluetoothAudio,
  BluetoothConnected,
  BluetoothDisabled,
  BluetoothDrive,
  BluetoothSearching,
  BlurCircular,
  BlurLinear,
  BlurOff,
  BlurOn,
  Bolt,
  Book,
  BookOnline,
  Bookmark,
  BookmarkAdd,
  BookmarkAdded,
  BookmarkBorder,
  BookmarkRemove,
  Bookmarks,
  BorderAll,
  BorderBottom,
  BorderClear,
  BorderColor,
  BorderHorizontal,
  BorderInner,
  BorderLeft,
  BorderOuter,
  BorderRight,
  BorderStyle,
  BorderTop,
  BorderVertical,
  Boy,
  BrandingWatermark,
  BreakfastDining,
  Brightness1,
  Brightness2,
  Brightness3,
  Brightness4,
  Brightness5,
  Brightness6,
  Brightness7,
  BrightnessAuto,
  BrightnessHigh,
  BrightnessLow,
  BrightnessMedium,
  BrokenImage,
  BrowseGallery,
  BrowserNotSupported,
  BrowserUpdated,
  BrunchDining,
  Brush,
  BubbleChart,
  BugReport,
  Build,
  BuildCircle,
  Bungalow,
  BurstMode,
  BusAlert,
  Business,
  BusinessCenter,
  Cabin,
  Cable,
  Cached,
  Cake,
  Calculate,
  CalendarMonth,
  CalendarToday,
  CalendarViewDay,
  CalendarViewMonth,
  CalendarViewWeek,
  Call,
  CallEnd,
  CallMade,
  CallMerge,
  CallMissed,
  CallMissedOutgoing,
  CallReceived,
  CallSplit,
  CallToAction,
  Camera,
  CameraAlt,
  CameraEnhance,
  CameraFront,
  CameraIndoor,
  CameraOutdoor,
  CameraRear,
  CameraRoll,
  Cameraswitch,
  Campaign,
  Cancel,
  CancelPresentation,
  CancelScheduleSend,
  CandlestickChart,
  CarCrash,
  CarRental,
  CarRepair,
  CardGiftcard,
  CardMembership,
  CardTravel,
  Carpenter,
  Cases,
  Casino,
  Cast,
  CastConnected,
  CastForEducation,
  Castle,
  CatchingPokemon,
  Category,
  Celebration,
  CellTower,
  CellWifi,
  CenterFocusStrong,
  CenterFocusWeak,
  Chair,
  ChairAlt,
  Chalet,
  ChangeCircle,
  ChangeHistory,
  ChargingStation,
  Chat,
  ChatBubble,
  ChatBubbleOutline,
  Check,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircle,
  CheckCircleOutline,
  Checkroom,
  ChevronLeft,
  ChevronRight,
  ChildCare,
  ChildFriendly,
  ChromeReaderMode,
  Church,
  Circle,
  CircleNotifications,
  Class,
  CleanHands,
  CleaningServices,
  Clear,
  ClearAll,
  Close,
  CloseFullscreen,
  ClosedCaption,
  ClosedCaptionDisabled,
  ClosedCaptionOff,
  Cloud,
  CloudCircle,
  CloudDone,
  CloudDownload,
  CloudOff,
  CloudQueue,
  CloudSync,
  CloudUpload,
  Co2,
  CoPresent,
  Code,
  CodeOff,
  Coffee,
  CoffeeMaker,
  Collections,
  CollectionsBookmark,
  ColorLens,
  Colorize,
  Comment,
  CommentBank,
  CommentsDisabled,
  Commit,
  Commute,
  Compare,
  CompareArrows,
  CompassCalibration,
  Compress,
  Computer,
  ConfirmationNumber,
  ConnectWithoutContact,
  ConnectedTv,
  ConnectingAirports,
  Construction,
  ContactMail,
  ContactPage,
  ContactPhone,
  ContactSupport,
  Contactless,
  Contacts,
  ContentCopy,
  ContentCut,
  ContentPaste,
  ContentPasteGo,
  ContentPasteOff,
  ContentPasteSearch,
  Contrast,
  ControlCamera,
  ControlPoint,
  ControlPointDuplicate,
  Cookie,
  CopyAll,
  Copyright,
  Coronavirus,
  CorporateFare,
  Cottage,
  Countertops,
  Create,
  CreateNewFolder,
  CreditCard,
  CreditCardOff,
  CreditScore,
  Crib,
  CrisisAlert,
  Crop,
  Crop169,
  Crop32,
  Crop54,
  Crop75,
  CropDin,
  CropFree,
  CropLandscape,
  CropOriginal,
  CropPortrait,
  CropRotate,
  CropSquare,
  Css,
  CurrencyBitcoin,
  CurrencyExchange,
  CurrencyFranc,
  CurrencyLira,
  CurrencyPound,
  CurrencyRuble,
  CurrencyRupee,
  CurrencyYen,
  CurrencyYuan,
  Curtains,
  CurtainsClosed,
  Cyclone,
  Dangerous,
  DarkMode,
  Dashboard,
  DashboardCustomize,
  DataArray,
  DataObject,
  DataSaverOff,
  DataSaverOn,
  DataThresholding,
  DataUsage,
  DateRange,
  Deblur,
  Deck,
  Dehaze,
  Delete,
  DeleteForever,
  DeleteOutline,
  DeleteSweep,
  DeliveryDining,
  DensityLarge,
  DensityMedium,
  DensitySmall,
  DepartureBoard,
  Description,
  Deselect,
  DesignServices,
  DesktopAccessDisabled,
  DesktopMac,
  DesktopWindows,
  Details,
  DeveloperBoard,
  DeveloperBoardOff,
  DeveloperMode,
  DeviceHub,
  DeviceThermostat,
  DeviceUnknown,
  Devices,
  DevicesFold,
  DevicesOther,
  DialerSip,
  Dialpad,
  Diamond,
  Difference,
  Dining,
  DinnerDining,
  Directions,
  DirectionsBike,
  DirectionsBoat,
  DirectionsBoatFilled,
  DirectionsBus,
  DirectionsBusFilled,
  DirectionsCar,
  DirectionsCarFilled,
  DirectionsOff,
  DirectionsRailway,
  DirectionsRailwayFilled,
  DirectionsRun,
  DirectionsSubway,
  DirectionsSubwayFilled,
  DirectionsTransit,
  DirectionsTransitFilled,
  DirectionsWalk,
  DirtyLens,
  DisabledByDefault,
  DiscFull,
  Discount,
  DisplaySettings,
  Dns,
  DoDisturb,
  DoDisturbAlt,
  DoDisturbOff,
  DoDisturbOn,
  DoNotDisturb,
  DoNotDisturbAlt,
  DoNotDisturbOff,
  DoNotDisturbOn,
  DoNotDisturbOnTotalSilence,
  DoNotStep,
  DoNotTouch,
  Dock,
  DocumentScanner,
  Domain,
  DomainAdd,
  DomainDisabled,
  DomainVerification,
  Done,
  DoneAll,
  DoneOutline,
  DonutLarge,
  DonutSmall,
  DoorBack,
  DoorFront,
  DoorSliding,
  Doorbell,
  DoubleArrow,
  DownhillSkiing,
  Download,
  DownloadDone,
  DownloadForOffline,
  Downloading,
  Drafts,
  DragHandle,
  DragIndicator,
  DriveEta,
  DriveFileMove,
  DriveFileRenameOutline,
  DriveFolderUpload,
  Dry,
  DryCleaning,
  Duo,
  Dvr,
  DynamicFeed,
  DynamicForm,
  EMobiledata,
  Earbuds,
  EarbudsBattery,
  East,
  EdgesensorHigh,
  EdgesensorLow,
  Edit,
  EditAttributes,
  EditLocation,
  EditLocationAlt,
  EditNotifications,
  EditOff,
  EditRoad,
  Egg,
  EggAlt,
  EightK,
  EightKPlus,
  EightMp,
  EighteenMp,
  EightteenMp,
  Eject,
  Elderly,
  ElderlyWoman,
  ElectricBike,
  ElectricBolt,
  ElectricCar,
  ElectricMeter,
  ElectricMoped,
  ElectricRickshaw,
  ElectricScooter,
  ElectricalServices,
  Elevator,
  ElevenMp,
  Email,
  EmergencyRecording,
  EmergencyShare,
  EmojiEmotions,
  EmojiEvents,
  EmojiFlags,
  EmojiFoodBeverage,
  EmojiNature,
  EmojiObjects,
  EmojiPeople,
  EmojiSymbols,
  EmojiTransportation,
  EnergySavingsLeaf,
  Engineering,
} from "@mui/icons-material";
////////////////////////////////////////////////////////////////////////////////////////////////////// End Section Imports

////////////////////////////////////////////////////////////////////////////////////////////////////// Start Section Class
export default class Icon extends React.Component {
  render() {
    return (
      <section className="p-3">
        <section className="row">
          <section className="row d-flex align-items-center justify-content-center">
            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Abc />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AcUnit />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AccessAlarm />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AccessAlarms />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AccessTime />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AccessTimeFilled />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Accessibility />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AccessibilityNew />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Accessible />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AccessibleForward />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AccountBalance />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AccountBalanceWallet />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AccountBox />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AccountCircle />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AccountTree />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AdUnits />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Adb />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Add />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddAPhoto />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddAlarm />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddAlert />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddBox />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddBusiness />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddCard />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddCircle />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddCircleOutline />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddComment />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddIcCall />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddLink />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddLocation />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddLocationAlt />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddModerator />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddPhotoAlternate />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddReaction />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddRoad />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddShoppingCart />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddTask />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddToDrive />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddToHomeScreen />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddToPhotos />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AddToQueue />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Addchart />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AdfScanner />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Adjust />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AdminPanelSettings />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Agriculture />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Air />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AirlineSeatFlat />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AirlineSeatFlatAngled />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AirlineSeatIndividualSuite />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AirlineSeatLegroomExtra />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AirlineSeatLegroomNormal />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AirlineSeatLegroomReduced />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AirlineSeatReclineExtra />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AirlineSeatReclineNormal />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AirlineStops />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Airlines />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AirplaneTicket />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AirplanemodeActive />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AirplanemodeInactive />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Airplay />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AirportShuttle />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Alarm />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AlarmAdd />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AlarmOff />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AlarmOn />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Album />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AlignHorizontalCenter />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AlignHorizontalLeft />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AlignHorizontalRight />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AlignVerticalBottom />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AlignVerticalCenter />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AlignVerticalTop />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AllInbox />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AllInclusive />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AllOut />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AltRoute />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AlternateEmail />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Analytics />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Anchor />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Android />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Animation />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Announcement />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Aod />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Apartment />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Api />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AppBlocking />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AppRegistration />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AppSettingsAlt />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AppShortcut />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Apple />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Approval />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Apps />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AppsOutage />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Architecture />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Archive />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ArrowBack />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ArrowBackIos />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ArrowBackIosNew />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ArrowCircleDown />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ArrowCircleLeft />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ArrowCircleRight />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ArrowCircleUp />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ArrowDownward />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ArrowDropDown />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ArrowDropDownCircle />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ArrowDropUp />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ArrowForward />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ArrowForwardIos />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ArrowLeft />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ArrowRight />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ArrowRightAlt />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ArrowUpward />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ArtTrack />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Article />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AspectRatio />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Assessment />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Assignment />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AssignmentInd />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AssignmentLate />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AssignmentReturn />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AssignmentReturned />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AssignmentTurnedIn />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Assistant />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AssistantDirection />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AssistantPhoto />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AssuredWorkload />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Atm />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AttachEmail />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AttachFile />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AttachMoney />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Attachment />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Attractions />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Attribution />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AudioFile />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Audiotrack />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AutoAwesome />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AutoAwesomeMosaic />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AutoAwesomeMotion />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AutoDelete />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AutoFixHigh />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AutoFixNormal />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AutoFixOff />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AutoGraph />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AutoMode />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AutoStories />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AutofpsSelect />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Autorenew />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <AvTimer />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BabyChangingStation />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Backpack />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Backspace />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Backup />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BackupTable />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Badge />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BakeryDining />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Balance />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Balcony />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Ballot />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BarChart />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BatchPrediction />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Bathroom />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Bathtub />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Battery0Bar />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Battery1Bar />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Battery20 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Battery2Bar />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Battery30 />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Battery3Bar />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Battery4Bar />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Battery50 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Battery5Bar />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Battery60 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Battery6Bar />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Battery80 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Battery90 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BatteryAlert />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BatteryCharging20 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BatteryCharging30 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BatteryCharging50 />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BatteryCharging60 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BatteryCharging80 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BatteryCharging90 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BatteryChargingFull />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BatteryFull />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BatterySaver />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BatteryStd />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BatteryUnknown />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BeachAccess />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Bed />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BedroomBaby />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BedroomChild />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BedroomParent />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Bedtime />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BedtimeOff />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Beenhere />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Bento />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BikeScooter />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Biotech />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Blender />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BlindsClosed />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Block />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Bloodtype />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Bluetooth />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BluetoothAudio />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BluetoothConnected />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BluetoothDisabled />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BluetoothDrive />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BluetoothSearching />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BlurCircular />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BlurLinear />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BlurOff />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BlurOn />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Bolt />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Book />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BookOnline />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Bookmark />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BookmarkAdd />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BookmarkAdded />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BookmarkBorder />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BookmarkRemove />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Bookmarks />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BorderAll />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BorderBottom />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BorderClear />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BorderClear />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BorderColor />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BorderHorizontal />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BorderInner />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BorderLeft />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BorderOuter />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BorderRight />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BorderStyle />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BorderTop />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BorderVertical />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Boy />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BrandingWatermark />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BreakfastDining />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Brightness1 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Brightness2 />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Brightness3 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Brightness4 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Brightness5 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Brightness6 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Brightness7 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BrightnessAuto />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BrightnessHigh />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BrightnessLow />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BrightnessMedium />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BrokenImage />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BrowseGallery />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BrowserNotSupported />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BrowserUpdated />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BrunchDining />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Brush />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BubbleChart />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BugReport />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Build />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BuildCircle />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Bungalow />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BurstMode />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BusAlert />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Business />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <BusinessCenter />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Cabin />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Cable />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Cached />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Cake />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Calculate />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CalendarMonth />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CalendarToday />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CalendarViewDay />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CalendarViewMonth />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CalendarViewWeek />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Call />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CallEnd />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CallMade />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CallMerge />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CallMissed />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CallMissedOutgoing />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CallReceived />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CallSplit />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CallToAction />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Camera />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CameraAlt />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CameraEnhance />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CameraFront />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CameraIndoor />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CameraOutdoor />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CameraRear />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CameraRoll />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Cameraswitch />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Campaign />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Cancel />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CancelPresentation />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CancelScheduleSend />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CandlestickChart />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CarCrash />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CarRental />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CarRepair />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CardGiftcard />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CardMembership />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CardTravel />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Carpenter />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Cases />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Casino />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Cast />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CastConnected />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CastForEducation />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Castle />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CatchingPokemon />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Category />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Celebration />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CellTower />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CellWifi />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CenterFocusStrong />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CenterFocusWeak />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Chair />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ChairAlt />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Chalet />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ChangeCircle />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ChangeHistory />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ChargingStation />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Chat />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ChatBubble />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ChatBubbleOutline />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Check />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CheckBox />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CheckBoxOutlineBlank />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CheckCircle />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CheckCircleOutline />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Checkroom />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ChevronLeft />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ChevronRight />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ChildCare />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ChildFriendly />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ChromeReaderMode />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Church />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Circle />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CircleNotifications />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Class />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CleanHands />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CleaningServices />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Clear />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ClearAll />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Close />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CloseFullscreen />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ClosedCaption />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ClosedCaptionDisabled />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ClosedCaptionOff />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Cloud />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CloudCircle />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CloudDone />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CloudDownload />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CloudOff />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CloudQueue />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CloudSync />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CloudUpload />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Co2 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CoPresent />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Code />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CodeOff />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Coffee />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CoffeeMaker />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Collections />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CollectionsBookmark />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ColorLens />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Colorize />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Comment />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CommentBank />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CommentsDisabled />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Commit />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Commute />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Compare />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CompareArrows />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CompassCalibration />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Compress />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Computer />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ConfirmationNumber />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ConnectWithoutContact />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ConnectedTv />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ConnectingAirports />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Construction />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ContactMail />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ContactPage />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ContactPhone />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ContactSupport />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Contactless />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Contacts />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ContentCopy />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ContentCut />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ContentPaste />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ContentPasteGo />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ContentPasteOff />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ContentPasteSearch />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Contrast />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ControlCamera />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ControlPoint />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ControlPointDuplicate />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Cookie />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CopyAll />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Copyright />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Coronavirus />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CorporateFare />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Cottage />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Countertops />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Create />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CreateNewFolder />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CreditCard />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CreditCardOff />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CreditScore />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Crib />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CrisisAlert />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Crop />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Crop169 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Crop32 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Crop54 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Crop75 />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CropDin />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CropFree />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CropLandscape />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CropOriginal />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CropPortrait />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CropRotate />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CropSquare />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Css />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CurrencyBitcoin />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CurrencyExchange />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CurrencyFranc />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CurrencyLira />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CurrencyPound />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CurrencyRuble />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CurrencyRupee />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CurrencyYen />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CurrencyYuan />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Curtains />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <CurtainsClosed />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Cyclone />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Dangerous />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DarkMode />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Dashboard />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DashboardCustomize />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DataArray />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DataObject />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DataSaverOff />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DataSaverOn />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DataThresholding />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DataUsage />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DateRange />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Deblur />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Deck />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Dehaze />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Delete />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DeleteForever />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DeleteOutline />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DeleteSweep />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DeliveryDining />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DensityLarge />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DensityMedium />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DensitySmall />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DepartureBoard />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Description />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Deselect />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DesignServices />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DesktopAccessDisabled />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DesktopMac />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DesktopWindows />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Details />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DeveloperBoard />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DeveloperBoardOff />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DeveloperMode />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DeviceHub />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DeviceThermostat />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DeviceUnknown />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Devices />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DevicesFold />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DevicesOther />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DialerSip />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Dialpad />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Diamond />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Difference />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Dining />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DinnerDining />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Directions />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DirectionsBike />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DirectionsBoat />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DirectionsBoatFilled />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DirectionsBus />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DirectionsBusFilled />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DirectionsCar />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DirectionsCarFilled />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DirectionsOff />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DirectionsRailway />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DirectionsRailwayFilled />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DirectionsRun />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DirectionsSubway />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DirectionsSubwayFilled />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DirectionsTransit />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DirectionsTransitFilled />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DirectionsWalk />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DirtyLens />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DisabledByDefault />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DiscFull />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Discount />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DisplaySettings />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Dns />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DoDisturb />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DoDisturbAlt />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DoDisturbOff />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DoDisturbOn />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DoNotDisturb />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DoNotDisturbAlt />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DoNotDisturbOff />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DoNotDisturbOn />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DoNotDisturbOnTotalSilence />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DoNotStep />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DoNotTouch />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Dock />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DocumentScanner />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Domain />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DomainAdd />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DomainDisabled />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DomainVerification />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Done />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DoneAll />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DoneOutline />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DonutLarge />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DonutSmall />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DoorBack />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DoorFront />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DoorSliding />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Doorbell />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DoubleArrow />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DownhillSkiing />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Download />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DownloadDone />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DownloadForOffline />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Downloading />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Drafts />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DragHandle />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DragIndicator />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DriveEta />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DriveFileMove />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DriveFileRenameOutline />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DriveFolderUpload />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Dry />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DryCleaning />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Duo />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Dvr />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DynamicFeed />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <DynamicForm />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EMobiledata />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Earbuds />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EarbudsBattery />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <East />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EdgesensorHigh />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EdgesensorLow />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Edit />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EditAttributes />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EditLocation />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EditLocationAlt />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EditNotifications />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EditOff />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EditRoad />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Egg />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EggAlt />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EightK />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EightKPlus />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EightMp />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EighteenMp />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EightteenMp />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Eject />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Elderly />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ElderlyWoman />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ElectricBike />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ElectricBolt />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ElectricCar />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ElectricMeter />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ElectricMoped />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ElectricRickshaw />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ElectricScooter />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ElectricalServices />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Elevator />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <ElevenMp />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Email />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EmergencyRecording />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EmergencyShare />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EmojiEmotions />
                  </section>
                </section>
              </section>
            </section>

            <section className="d-flex col-12 p-2">
              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EmojiEvents />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EmojiFlags />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EmojiFoodBeverage />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EmojiNature />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EmojiObjects />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EmojiPeople />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EmojiSymbols />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EmojiTransportation />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <EnergySavingsLeaf />
                  </section>
                </section>
              </section>

              <section className="col-1 d-flex align-items-center justify-content-center">
                <section className="p-3">
                  <section className="p-3 bg-t-o b-r-10">
                    <Engineering />
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
    );
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////// End Section Class
